import Vue from 'vue'

import App from './App.vue'
import router from './router'
import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify'
import i18n from './i18n';
import '@mdi/font/css/materialdesignicons.css' 
import store from './store'
import axios from './axios'
import '@fortawesome/fontawesome-free/css/all.css'    
import '@fortawesome/fontawesome-free/js/all.js'  
import VueRouterUserRoles from "vue-router-user-roles";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import moment from 'moment'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'


Vue.use(VueMeta)
Vue.use(VueSocialSharing);
// import fancyBox from 'vue-fancybox';

Vue.config.productionTip = false
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
});

Vue.filter('formatMon', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM DD')
  }
});
 

//Google Map// 
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBPFDMtjV0nyVdedEBT169klxYqGCvy3tI',
    libraries: 'places',
  },
})



  

Vue.filter('formatMonn', function(value) {
  if (value) {
    return moment(String(value)).format('MMMM DD , YYYY')
  }
});
  
Vue.mixin({
  data: function () {
    return {
      Url: 'https://abnakr.org/back_end',
      http: 'https://'
    }
  }
})


Vue.use(VueRouterUserRoles, {router});
Vue.use(VueSweetalert2);
Vue.config.silent = true
Vue.config.productionTip = false
let authenticate = Promise.resolve({ role:store.state.role });

authenticate.then(user => {
  Vue.prototype.$user.set(user);


  new Vue({
    router,
    render: h => h(App),
    vuetify,
    i18n,
    axios,
    store
  }).$mount('#app')
  


})


;
